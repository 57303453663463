/**
 * Layout Page
 * With all app layout, displayed after the user logs in
 */
import { ReactNode, useEffect, useState, useRef } from 'react';
import React from 'react';

import {
  AppBar,
  Toolbar,
  CssBaseline,
  useTheme,
  useMediaQuery,
  Typography,
  SvgIcon,
  Link as MUILink,
  Box,
  Grid,
  Hidden
} from '@mui/material';

import { useNavigate, Link } from 'react-router-dom';
import DrawerComponent from '../DrawerComponent/DrawerComponent';
import './Layout.scss';
import { useStore } from 'lnox';
import { thunkLogoutAction } from '../../store/actions/authActions';
import { ReactComponent as Notifications } from '../../assets/Notifications.svg';
import { ReactComponent as Settings } from '../../assets/Settings.svg';
import UserProfile from '../UserProfile/UserProfile';
import Offline from '../Common/Offline';
import SnackBarInput from '../Common/SnackBar';
import ActivityDetector from '../ActivityDetector';
import { ACTIVITY_AUTO_LOGOUT, ACTIVITY_TIMEOUT } from '../../store/constants';
import AccountList from './AccountList';
import useDidMountEffect from '../../Hooks/useDidMountEffect';
import {
  DEFAULT_TRANSACTIONS_PAYLOAD,
  DEFAULT_DATE_TIME,
  DEFAULT_BATCHES_PAYLOAD,
} from '../../pages/Reporting/Reporting';
import {
  thunkReportingFetchBatchesAction,
  thunkReportingFetchTransactionsSummaryAction,
} from '../../store/actions/reportingActions';
import { thunkUsersFetchAction } from '../../store/actions/userActions';
import { thunkClientFetchListAction } from '../../store/actions/clientActions';



const Layout: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const [activeLink, setActiveLink] = useState<number>(0);

  const {
    dispatch,
    state: {
      app: { statusMessages = [] },
      auth: { isLoggedIn = false, clientId },
    },
  } = useStore();

  const logout = async () => {
    await dispatch(thunkLogoutAction());
    navigate('/signin');
  };

  /**
   * Watch offline / online app status
   * Render offline page when user if offline
   * Main layout when user is online
   */
  useEffect(() => {
    const events = ['online', 'offline'];
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    events.map((event: string) =>
      window.addEventListener(event, handleOnlineStatusChange)
    );

    return () => {
      events.map((event: string) =>
        window.removeEventListener(event, handleOnlineStatusChange)
      );
    };
  });

  /**
   * This is to logout user after he closes the tab or window
   */
  useEffect(() => {
    const events = ['load'];
    events.map((event: string) =>
      window.addEventListener(event, handleBrowserLoad)
    );
    setWindowPathAsActive(window.location.pathname);
  });

  const fetchTransactionsList = (search?: any, filter?: any): Promise<any> => {
    const queryParams = {
      ...DEFAULT_TRANSACTIONS_PAYLOAD,
      term: DEFAULT_DATE_TIME,
      skip: 0,
    };

    return dispatch(
      thunkReportingFetchTransactionsSummaryAction({
        ...queryParams,
        // ...payload,
      })
    );
  };

  const fetchBatchesList = (): Promise<any> => {
    const queryParams = {
      ...DEFAULT_BATCHES_PAYLOAD,
    };
    return dispatch(
      thunkReportingFetchBatchesAction({
        ...queryParams,
      })
    );
  };

  const fetchUsersList = (search?: any, filter?: any): Promise<any> => {
    const queryParams = {
      take: '1000',
      sortColumn: 'FirstName',
      sortOrder: '1',
      skip: '0',
      searchText: '',
    };
    return dispatch(thunkUsersFetchAction(queryParams));
  };

  const fetchClientsList = (search?: any): Promise<any> => {
    const queryParams = {
      take: 40,
      searchText: '',
      sortOrder: '-1',
      sortColumn: 'DateAdded',
      skip: 0,
    };
    return dispatch(
      thunkClientFetchListAction({
        ...queryParams,
      })
    );
  };

  useEffect(() => {
    if (isLoggedIn === true && clientId) {
      fetchUsersList().then(() => {
        fetchClientsList();
      }).then(() => {
        fetchTransactionsList();
      }).then(() => {
        fetchBatchesList();
      })
    }


  }, [clientId, isLoggedIn]);

  const setWindowPathAsActive = (path: string) => {
    switch (true) {
      case path === '/accounts':
        setActiveLink(1);
        break;
      case path === '/users':
        setActiveLink(2);
        break;
      case path.startsWith('/terminal'):
        setActiveLink(3);
        break;
      default:
        setActiveLink(0);
        break;
    }
  };

  const handleProfileSettingsClick = () => {
    navigate('/settings');
  };

  const handleBrowserLoad = () => {
    if (!sessionStorage.getItem('IsSessionActive')) {
      if (process.env.NODE_ENV !== 'test' && logout) {
        logout();
      }
    }
  };


  const renderAppBar = () => {
    const tabLinkTableWidth = 400;

    const NotificationsIcon = <SvgIcon
      className="RightMenuIcons"
      component={Notifications}
    />;
    const SettingsIcon = <SvgIcon
      className="RightMenuIcons"
      component={Settings}
    />;


    return <AppBar position="sticky" className="AppBarClass">
      <CssBaseline />
      <Toolbar
        className={
          'ToolBarMain ' + 
          (isMobile || isTablet ? 'TopBarForMobile' : 'TopBarName')
        }
      >
        <Hidden only={['xs', 'sm', 'md']}>
          <Grid id="a" container style={{ height: "45px" }} alignItems="center" alignContent="center">
            <Grid id="1" item xs={10} style={{}}>
              <table style={{ maxWidth: 500, marginTop: -10, width: "100%", height: "40px" }}>
                <tbody>
                  <tr>
                    <td>
                      <Box
                        style={{ marginTop: 10 }}
                        component="img"
                        sx={{
                          height: '38px',
                          width: '80px',
                        }}
                        alt="IPP integrated PAYMENT PROCESSING"
                        src="/logos/LogoDark.svg"
                      />
                    </td>

                    <td>
                      <Link className="LinkStyle"
                        to="/reporting"
                        onClick={() => {
                          setActiveLink(0);
                          localStorage.setItem('IsFinancialTransaction', 'false');
                        }}
                      >
                        <div
                          className={
                            activeLink === 0 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Reporting
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Link className="LinkStyle"
                        to="/accounts"
                        onClick={() => {
                          localStorage.setItem('IsFinancialTransaction', 'false');
                          setActiveLink(1);
                        }}
                      >
                        <div
                          className={
                            activeLink === 1 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Accounts
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Link
                        className="LinkStyle"
                        to="/users"
                        onClick={() => {
                          localStorage.setItem('IsFinancialTransaction', 'false');
                          setActiveLink(2);
                        }}
                      >
                        <div
                          className={
                            activeLink === 2 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Users
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Link
                        className="LinkStyle"
                        to="/terminal"
                        onClick={() => {
                          setActiveLink(3);
                        }}
                      >
                        <div
                          className={
                            activeLink === 3 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Terminal
                        </div>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>

            <Grid id="3" item xs={2} container justifyContent="flex-end">
              <div>
                <table style={{}}>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <AccountList />
                        </div>
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <UserProfile isTablet={isTablet}
                          onLogOut={logout}
                          onProfileSettingsClick={handleProfileSettingsClick} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>

          </Grid>
        </Hidden>

        <Hidden only={['xs', 'sm', 'lg', 'xl']}>
          <Grid id="a" container style={{ height: "45px" }} alignItems="center" alignContent="center">
            <Grid id="1" item xs={8} style={{}}>
              <table style={{ maxWidth: 400, marginTop: -10, width: "100%", height: "40px" }}>
                <tbody>
                  <tr>
                    <td>
                      <Box
                        style={{ marginTop: 10 }}
                        component="img"
                        sx={{
                          height: '38px',
                          width: '80px',
                        }}
                        alt="IPP integrated PAYMENT PROCESSING"
                        src="/logos/LogoDark.svg"
                      />
                    </td>

                    <td>
                      <Link className="LinkStyle"
                        to="/reporting"
                        onClick={() => {
                          setActiveLink(0);
                          localStorage.setItem('IsFinancialTransaction', 'false');
                        }}
                      >
                        <div
                          className={
                            activeLink === 0 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Reporting
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Link className="LinkStyle"
                        to="/accounts"
                        onClick={() => {
                          localStorage.setItem('IsFinancialTransaction', 'false');
                          setActiveLink(1);
                        }}
                      >
                        <div
                          className={
                            activeLink === 1 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Accounts
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Link
                        className="LinkStyle"
                        to="/users"
                        onClick={() => {
                          localStorage.setItem('IsFinancialTransaction', 'false');
                          setActiveLink(2);
                        }}
                      >
                        <div
                          className={
                            activeLink === 2 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Users
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Link
                        className="LinkStyle"
                        to="/terminal"
                        onClick={() => {
                          setActiveLink(3);
                        }}
                      >
                        <div
                          className={
                            activeLink === 3 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Terminal
                        </div>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>

            <Grid id="3" item xs={4} container justifyContent="flex-end">
              <div>
                <table style={{}}>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <AccountList />
                        </div>
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <UserProfile isTablet={isTablet}
                          onLogOut={logout}
                          onProfileSettingsClick={handleProfileSettingsClick} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>

          </Grid>
        </Hidden>

        <Hidden only={['xs', 'md', 'lg', 'xl']}>
          <Grid id="a" container style={{ height: "45px" }} alignItems="center" alignContent="center">
            <Grid id="1" item xs={8} style={{}}>
              <table style={{ marginLeft: 20, maxWidth: 400, marginTop: -10, maxHeight: 20, width: "100%", height: "40px" }}>
                <tbody>
                  <tr>
                    <td>
                      <Box
                        style={{ marginTop: 10 }}
                        component="img"
                        sx={{
                          height: '38px',
                          width: '80px',
                        }}
                        alt="IPP integrated PAYMENT PROCESSING"
                        src="/logos/LogoDark.svg"
                      />
                    </td>

                    <td>
                      <Link className="LinkStyle"
                        to="/reporting"
                        onClick={() => {
                          setActiveLink(0);
                          localStorage.setItem('IsFinancialTransaction', 'false');
                        }}
                      >
                        <div
                          className={
                            activeLink === 0 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Reporting
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Link className="LinkStyle"
                        to="/accounts"
                        onClick={() => {
                          localStorage.setItem('IsFinancialTransaction', 'false');
                          setActiveLink(1);
                        }}
                      >
                        <div
                          className={
                            activeLink === 1 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Accounts
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Link
                        className="LinkStyle"
                        to="/users"
                        onClick={() => {
                          localStorage.setItem('IsFinancialTransaction', 'false');
                          setActiveLink(2);
                        }}
                      >
                        <div
                          className={
                            activeLink === 2 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Users
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Link
                        className="LinkStyle"
                        to="/terminal"
                        onClick={() => {
                          setActiveLink(3);
                        }}
                      >
                        <div
                          className={
                            activeLink === 3 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Terminal
                        </div>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>

            <Grid id="3" item xs={4} container justifyContent="flex-end">
              <div>
                <table style={{ marginRight: 20 }}>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <AccountList />
                        </div>
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <UserProfile isTablet={isTablet}
                          onLogOut={logout}
                          hideClientName={true}
                          onProfileSettingsClick={handleProfileSettingsClick} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>

          </Grid>
        </Hidden>

        <Hidden only={['sm', 'md', 'lg', 'xl']}>
          <Grid id="a" container style={{ height: "45px" }} alignItems="center" alignContent="center">
            <Grid id="1" item xs={8} style={{}}>
              <table style={{ marginLeft: 20, maxWidth: 400, marginTop: -10, width: "100%", height: "40px" }}>
                <tbody>
                  <tr>
                    <td>
                      <Box
                        style={{ marginTop: 10 }}
                        component="img"
                        sx={{
                          height: '20px',
                          width: '46px',
                        }}
                        alt="IPP integrated PAYMENT PROCESSING"
                        src="/logos/LogoDark.svg"
                      />
                    </td>

                    <td>
                      <Link className="LinkStyle"
                        to="/reporting"
                        onClick={() => {
                          setActiveLink(0);
                          localStorage.setItem('IsFinancialTransaction', 'false');
                        }}
                      >
                        <div
                          className={
                            activeLink === 0 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Reporting
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Link className="LinkStyle"
                        to="/accounts"
                        onClick={() => {
                          localStorage.setItem('IsFinancialTransaction', 'false');
                          setActiveLink(1);
                        }}
                      >
                        <div
                          className={
                            activeLink === 1 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Accounts
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Link
                        className="LinkStyle"
                        to="/users"
                        onClick={() => {
                          localStorage.setItem('IsFinancialTransaction', 'false');
                          setActiveLink(2);
                        }}
                      >
                        <div
                          className={
                            activeLink === 2 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Users
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Link
                        className="LinkStyle"
                        to="/terminal"
                        onClick={() => {
                          setActiveLink(3);
                        }}
                      >
                        <div
                          className={
                            activeLink === 3 ? 'TabNamesOnActive' : 'TabNames'
                          }
                        >
                          Terminal
                        </div>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>

            <Grid id="3" item xs={4} container justifyContent="flex-end">
              <div>
                <table style={{ marginRight: 15 }}>
                  <tbody>
                    <tr>
                      <td style={{ maxWidth: 25, padding: 0 }}>
                        <AccountList />
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <UserProfile isTablet={isTablet}
                          hideClientName={true}
                          onLogOut={logout}
                          onProfileSettingsClick={handleProfileSettingsClick} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>

          </Grid>
        </Hidden>

      </Toolbar>
    </AppBar>

  }

  const renderMainLayout = () => {


    return (
      <ActivityDetector
        onLogout={logout}
        timeout={
          process.env.REACT_APP_ACTIVITY_TIMEOUT
            ? parseInt(process.env.REACT_APP_ACTIVITY_TIMEOUT)
            : ACTIVITY_TIMEOUT
        }
        autoDeclineTimeout={
          process.env.REACT_APP_ACTIVITY_AUTO_LOGOUT
            ? parseInt(process.env.REACT_APP_ACTIVITY_AUTO_LOGOUT)
            : ACTIVITY_AUTO_LOGOUT
        }
      >
        {renderAppBar()}

        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </ActivityDetector>
    );
  };

  return (
    <Box className="App">
      <Box className="LayoutStyledMainWrapper">

        {isOnline ? (
          <>{isLoggedIn ? renderMainLayout() : children}</>
        ) : (
          <Offline />
        )}
      </Box>
      {isLoggedIn &&
        statusMessages.map((message: any, index: number) => {
          return (
            <SnackBarInput
              key={Math.random()}
              message={message.text}
              severity={message?.type?.toLowerCase()}
            ></SnackBarInput>
          );
        })}
    </Box>
  );
};

export default Layout;

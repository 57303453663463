/**
 * User SignIn Page
 */
import 'react-phone-input-2/lib/material.css';
import './SignIn.scss';
import SignInForm from '../../components/Forms/SignIn';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import AccountLockedForm from '../../components/Forms/LockedAccount';
import { useStore } from 'lnox';
import React from 'react';
import ChangePasswordForm from '../../components/Forms/ChangePassword';
import TwoFactorAuthForm from '../../components/Forms/TwoFactorAuthForm';
import AuthVerificationForm from '../../components/Forms/AuthVerification';
import AuthVerificationFormChangePassword from '../../components/Forms/AuthVerification/AuthVerificationFormChangePassword';
import TwoFactorAuthChangePasswordForm from '../../components/Forms/TwoFactorAuthForm/TwoFactorAuthChangePasswordForm';

import { getDefaultKeys } from '../../libs/EncryptionHelper';
import Cookies from 'universal-cookie';
import { fecthEnvConfig } from '../../libs/helpers';
import { useNavigate } from 'react-router-dom';
import { clearStatusMessagesAction } from '../../store/actions/appActions';

enum AuthScreens {
  Login = 'Login',
  TwoFactorModeSelection = 'TwoFactorModeSelection',
  AccountLocked = 'AccountLocked',
  AuthVerification = 'AuthVerification',
  ChangePassword = 'ChangePassword',
  ForgotPassword = 'ForgotPassword',
  AuthVerificationChangePassword = 'AuthVerificationChangePassword',
}

const SignIn: React.FC<{}> = () => {
  const [authScreen, setAuthScreen] = useState<AuthScreens>(AuthScreens.Login);
  const [userEmail, setUserEmail] = useState<string>('');
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>('');
  const cookies = new Cookies();
  const navigate = useNavigate();
  const {
    dispatch,
    state: {
      auth: {
        reason,
        crypto,
        email,
        phoneNumber,
        userId,
        verificationSendSuccess,
        codeVerificationSuccess,
        passwordUpdateSuccess,
        intermediaryToken,
        isLoggedIn
      },
    },
  } = useStore();

  useEffect(() => {
    const successLoginConditions = [!reason, Object.keys(crypto).length];
    const reasonScreenMap: { [key: string]: AuthScreens } = {
      ADDITIONAL_AUTH_REQUIRED: AuthScreens.TwoFactorModeSelection,
      ADDITIONAL_VERIFICATION_REQUIRED: AuthScreens.AuthVerification,
      OBSOLETE: AuthScreens.ChangePassword,
      USER_ACCOUNT_LOCKED: AuthScreens.AccountLocked,
      AUTH_UPDATE_PASSWORD: (() => {
        if (verificationSendSuccess === false) {
          return AuthScreens.ForgotPassword;
        } else if (codeVerificationSuccess === false) {
          return AuthScreens.AuthVerificationChangePassword;
        } else {
          return AuthScreens.ChangePassword;
        }
      })(),
    };
    if (successLoginConditions.every(Boolean)) {
      dispatch(clearStatusMessagesAction());
    } else {
      setAuthScreen(reasonScreenMap[reason] || AuthScreens.Login);
    }
  }, [
    reason,
    crypto,
    userId,
    verificationSendSuccess,
    codeVerificationSuccess,
    passwordUpdateSuccess,
    intermediaryToken,
    dispatch,
  ]);

  useEffect(() => {
    if (localStorage.getItem('IsPhoneNumberLogin') === 'true') {
      setUserPhoneNumber(phoneNumber?.substring('1'));
      setUserEmail('');
    } else {
      setUserEmail(email);
      setUserPhoneNumber('');
    }
  }, [reason, email, phoneNumber]);

  useEffect(() => {
    if (isLoggedIn === true) {
      navigate('/reporting');
    }
  }, [isLoggedIn]);
  // handle Forgot Password click
  const handleForgotPassword = () => {
    setAuthScreen(AuthScreens.ForgotPassword);
  };

  // read env config and set the values in cookies
  const getAndSetDefaultKeys = async () => {
    let config: any = await fecthEnvConfig();
    await getDefaultKeys(config);
  };

  // render the login screen
  const renderLoginScreen = () => {
    return <SignInForm onForgotPassword={handleForgotPassword}></SignInForm>;
  };

  // render 2 factor auth screen
  const renderTwoFactorModeSelection = () => {
    return (
      <TwoFactorAuthForm
        userEmail={userEmail}
        userPhoneNumber={userPhoneNumber}
        onForgotPassword={() => setAuthScreen(AuthScreens.Login)}
      ></TwoFactorAuthForm>
    );
  };

  const renderTwoFactorForgotPasswordModeSelection = () => {
    return (
      <TwoFactorAuthChangePasswordForm
        onForgotPassword={() => setAuthScreen(AuthScreens.Login)}
      ></TwoFactorAuthChangePasswordForm>
    );
  };

  // render auth verification screen to enter otp
  const renderAuthVerificationScreen = () => {
    return (
      <AuthVerificationForm
        userEmail={userEmail}
        userPhoneNumber={userPhoneNumber}
        onBackToLogin={() => { 
          clearStatusMessagesAction(); 
          setAuthScreen(AuthScreens.Login)
        }}
      ></AuthVerificationForm>
    );
  };

  const renderAuthVerificationChangePasswordScreen = () => {
    return <AuthVerificationFormChangePassword userId={userId} 
    onBackToLogin={() => { 
      clearStatusMessagesAction(); 
      setAuthScreen(AuthScreens.Login)
    }} />;
  };

  // render user locked form
  const renderAccountLockedForm = () => {
    return (
      <AccountLockedForm
      onBackToLogin={() => { 
        clearStatusMessagesAction(); 
        setAuthScreen(AuthScreens.Login)
      }}
      ></AccountLockedForm>
    );
  };

  // render change password screen
  const renderChangePasswordForm = () => {
    return (
      <ChangePasswordForm
      onBackToLogin={() => { 
        clearStatusMessagesAction(); 
        setAuthScreen(AuthScreens.Login)
      }}
        isForgotPassword={true}
      ></ChangePasswordForm>
    );
  };

  const renderAuthScreen = (screenId: AuthScreens): JSX.Element => {
    const screenRenderMap = {
      [AuthScreens.Login]: renderLoginScreen,
      [AuthScreens.TwoFactorModeSelection]: renderTwoFactorModeSelection,
      [AuthScreens.AccountLocked]: renderAccountLockedForm,
      [AuthScreens.AuthVerification]: renderAuthVerificationScreen,
      [AuthScreens.ChangePassword]: renderChangePasswordForm,
      [AuthScreens.ForgotPassword]: renderTwoFactorForgotPasswordModeSelection,
      [AuthScreens.AuthVerificationChangePassword]:
        renderAuthVerificationChangePasswordScreen,
    };
    if (!cookies.get('ApiKeyValue')) {
      getAndSetDefaultKeys();
    }
    return <>{screenRenderMap[screenId]()}</>;
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className="App"
    >
      <Box className="StyledSigninWrapper">
        <Box textAlign="center">
          <Box
            component="img"
            sx={{
              height: 60,
              width: 250,
            }}
            alt="IPP integrated PAYMENT PROCESSING"
            src="/logos/LogoDark.svg"
          />
        </Box>
        {renderAuthScreen(authScreen)}
      </Box>
    </Box>
  );
};

export default SignIn;
